.projects {
    height: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    justify-content: center;
    padding: 1rem;
    border-radius: .25rem;
    object-fit: contain;
    margin-bottom: 50px;
  }

.project-li {
      background-color: rgb(248, 181, 118);
      padding: 1rem;
      border-radius: .25rem;
      width: 29.6rem;
      height: 25rem;
      opacity: .8;
  }
  
  .project-ul {
      margin-top: 0rem;
      margin-bottom: 0;
      padding: 1rem
  }

  .project-title {
      border-style: solid;
      border-top-style: none;
      border-left-style: none;
      border-right-style: none;
      margin-bottom: 1rem
  }

  .project-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center
  }

  .project-info {
      font-size: 1.25rem
  }
