.blogs {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    border-radius: .25rem;
    font-family: 'Open Sans Condensed', sans-serif;
    margin-bottom: 50px;
  }

  .blog-li {
    background-color: rgb(248, 181, 118);
    opacity: .8;
    padding: 1rem;
    border-radius: .25rem;
    width: 29.6rem;
    height: 16rem
}

.blog-ul {
    margin-top: 0rem;    
    margin-bottom: 0;
    padding: 1rem
}

.blog-title {
  border-style: solid;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  margin-bottom: 1rem
}


.blog-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

h4 {
  font-size: 1.5rem
}

.blog-info  {
  font-size: 1.25rem
}


