@import url('https://fonts.googleapis.com/css?family=IM+Fell+Great+Primer+SC&display=swap');

h1 {
    padding: 1rem 1rem 0rem 1rem;
    margin-bottom: 0;
    font-weight: 200;
    font-size: 5rem;
    font-family: 'Open Sans Condensed', sans-serif;
}

.vertical-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav-li {
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 40px;
    margin-right: 2rem;
    font-size: 1.75rem;
    color: whitesmoke;
    font-family: 'Open Sans Condensed', sans-serif;
}

.nav-li:hover {
    color:rgb(221, 99, 28)
}

.main-page {
    display: flex;
    flex-direction: column;
}

ul {
    margin-top: 6rem;
    list-style-type: none;
}





