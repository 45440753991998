@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=IM+Fell+Great+Primer+SC&display=swap);
@import url(https://fonts.googleapis.com/css?family=IM+Fell+Great+Primer+SC&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap);
body {
  background-color: #282c34;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  color: rgb(5, 5, 70)
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.horizontal-div {
    box-shadow: 0px 0px 0px 10px rgb#101038;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23aaa9a1'/%3E%3Cstop offset='1' stop-color='%23ffdc69'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23ffa973' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ffa973' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23ffa973' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ffa973' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
}

#header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 1rem

}

#icons-container {
    object-fit: contain;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem
}

.icon-facebook {
    background-image: url(/static/media/iconfinder-facebook-icon.18264351.svg);
    background-size: 100%;
    width: 60px;
    height: 60px;
    margin-left: 1rem;
    cursor: pointer;
}

.icon-linkedin {
    background-image: url(/static/media/iconfinder-linkedin-icon.df2c9b16.svg);
    background-size: 100%;
    width: 60px;
    height: 60px;
    margin-left: 1rem;
    cursor: pointer
}

.icon-github {
    background-image: url(/static/media/iconfinder-github-icon.46a96376.svg);
    background-size: 100%;
    width: 52px;
    height: 52px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 2.5px
}

.icon-medium {
    background-image: url(/static/media/iconfinder-medium-icon.623eceb5.svg);
    background-size: 100%;
    width: 55px;
    height: 55px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 2.5px
}

div a {
    display: block;
    height: 50px;
    /* text-indent: -9999px; */
    object-fit: contain;
}

#title-cody {
    margin-top: 0rem;
    
}

h6 {
    font-family: 'Open Sans Condensed', sans-serif;
    display: flex;
    justify-content: flex-start;
    padding-left: 1.5rem;
    margin: 0;
    color: rgb(5, 5, 70);
    font-size: 2rem;
    font-weight: 200;
    margin-top: -.25rem;
    padding-bottom: .25rem;
}


h1 {
    padding: 1rem 1rem 0rem 1rem;
    margin-bottom: 0;
    font-weight: 200;
    font-size: 5rem;
    font-family: 'Open Sans Condensed', sans-serif;
}

.vertical-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav-li {
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 40px;
    margin-right: 2rem;
    font-size: 1.75rem;
    color: whitesmoke;
    font-family: 'Open Sans Condensed', sans-serif;
}

.nav-li:hover {
    color:rgb(221, 99, 28)
}

.main-page {
    display: flex;
    flex-direction: column;
}

ul {
    margin-top: 6rem;
    list-style-type: none;
}






.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
    vertical-align: middle;
    padding: 1rem;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Open Sans Condensed', sans-serif;
}

img {
    object-fit: contain;
    background-color: lightgray
}

.submit-message {
    color: rgb(5, 5, 70);
    font-family: 'Open Sans Condensed', sans-serif;
    cursor: pointer;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 3rem;    border-radius: .25rem;

}

.about-me {
    margin-top: 0rem;
    margin-bottom: 2rem;
    padding: 2rem;
    vertical-align: center;
    background-color: rgb(248, 181, 118);
    border-radius: .25rem;
    opacity: .8;
}

#about-me-p {
    margin-top: 3rem;
    font-size: 1.25rem
}

input {
    height: 1.25rem;

}

textarea {
    height: 4rem;
}

.contact-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5rem;
    background-color:rgb(228, 154, 86);
    opacity: .8;
    padding: 1rem;
    border-radius: .25rem;
}

#input-button {
    width: 5rem;
    align-self: center;
    margin-top: 1.5rem;
    font-family: 'Open Sans Condensed', sans-serif; 
    cursor: pointer;
    color: ;
}

.projects {
    height: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    justify-content: center;
    padding: 1rem;
    border-radius: .25rem;
    object-fit: contain;
    margin-bottom: 50px;
  }

.project-li {
      background-color: rgb(248, 181, 118);
      padding: 1rem;
      border-radius: .25rem;
      width: 29.6rem;
      height: 25rem;
      opacity: .8;
  }
  
  .project-ul {
      margin-top: 0rem;
      margin-bottom: 0;
      padding: 1rem
  }

  .project-title {
      border-style: solid;
      border-top-style: none;
      border-left-style: none;
      border-right-style: none;
      margin-bottom: 1rem
  }

  .project-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center
  }

  .project-info {
      font-size: 1.25rem
  }

.blogs {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    border-radius: .25rem;
    font-family: 'Open Sans Condensed', sans-serif;
    margin-bottom: 50px;
  }

  .blog-li {
    background-color: rgb(248, 181, 118);
    opacity: .8;
    padding: 1rem;
    border-radius: .25rem;
    width: 29.6rem;
    height: 16rem
}

.blog-ul {
    margin-top: 0rem;    
    margin-bottom: 0;
    padding: 1rem
}

.blog-title {
  border-style: solid;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  margin-bottom: 1rem
}


.blog-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

h4 {
  font-size: 1.5rem
}

.blog-info  {
  font-size: 1.25rem
}



#resume {
    margin-top: 0rem;
    width: 100%;
    box-shadow: 0px 0px 20px 0px rgb(248, 181, 118);

    border-radius: .05px;
}

.resume {
    margin-bottom: 50px;
    /* height: 100%; */
    width: 50%;
    margin-left: 25%;
    font-family: 'Open Sans Condensed', sans-serif;
}






