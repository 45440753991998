#resume {
    margin-top: 0rem;
    width: 100%;
    box-shadow: 0px 0px 20px 0px rgb(248, 181, 118);

    border-radius: .05px;
}

.resume {
    margin-bottom: 50px;
    /* height: 100%; */
    width: 50%;
    margin-left: 25%;
    font-family: 'Open Sans Condensed', sans-serif;
}





