@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap');

.horizontal-div {
    box-shadow: 0px 0px 0px 10px rgb#101038;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23aaa9a1'/%3E%3Cstop offset='1' stop-color='%23ffdc69'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23ffa973' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ffa973' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23ffa973' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23ffa973' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
}

#header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 1rem

}

#icons-container {
    object-fit: contain;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem
}

.icon-facebook {
    background-image: url(../Images/iconfinder-facebook-icon.svg);
    background-size: 100%;
    width: 60px;
    height: 60px;
    margin-left: 1rem;
    cursor: pointer;
}

.icon-linkedin {
    background-image: url(../Images/iconfinder-linkedin-icon.svg);
    background-size: 100%;
    width: 60px;
    height: 60px;
    margin-left: 1rem;
    cursor: pointer
}

.icon-github {
    background-image: url(../Images/iconfinder-github-icon.svg);
    background-size: 100%;
    width: 52px;
    height: 52px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 2.5px
}

.icon-medium {
    background-image: url(../Images/iconfinder-medium-icon.svg);
    background-size: 100%;
    width: 55px;
    height: 55px;
    margin-left: 1rem;
    cursor: pointer;
    margin-top: 2.5px
}

div a {
    display: block;
    height: 50px;
    /* text-indent: -9999px; */
    object-fit: contain;
}

#title-cody {
    margin-top: 0rem;
    
}

h6 {
    font-family: 'Open Sans Condensed', sans-serif;
    display: flex;
    justify-content: flex-start;
    padding-left: 1.5rem;
    margin: 0;
    color: rgb(5, 5, 70);
    font-size: 2rem;
    font-weight: 200;
    margin-top: -.25rem;
    padding-bottom: .25rem;
}

