@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap');

input {
    height: 1.25rem;

}

textarea {
    height: 4rem;
}

.contact-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5rem;
    background-color:rgb(228, 154, 86);
    opacity: .8;
    padding: 1rem;
    border-radius: .25rem;
}

#input-button {
    width: 5rem;
    align-self: center;
    margin-top: 1.5rem;
    font-family: 'Open Sans Condensed', sans-serif; 
    cursor: pointer;
    color: ;
}
