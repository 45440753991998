@import url('https://fonts.googleapis.com/css?family=IM+Fell+Great+Primer+SC&display=swap');

.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
    vertical-align: middle;
    padding: 1rem;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Open Sans Condensed', sans-serif;
}

img {
    object-fit: contain;
    background-color: lightgray
}

.submit-message {
    color: rgb(5, 5, 70);
    font-family: 'Open Sans Condensed', sans-serif;
    cursor: pointer;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 3rem;    border-radius: .25rem;

}

.about-me {
    margin-top: 0rem;
    margin-bottom: 2rem;
    padding: 2rem;
    vertical-align: center;
    background-color: rgb(248, 181, 118);
    border-radius: .25rem;
    opacity: .8;
}

#about-me-p {
    margin-top: 3rem;
    font-size: 1.25rem
}
